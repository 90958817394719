<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isOperateColumn="false"
    >
      <template #searchSlot>
        <v-date-picker
          label="通知时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="请选择通知开始时间"
          endPlaceholder="请选择通知结束时间"
        />
        <v-select
          clearable
          v-model="searchParam.type"
          :options="typeOps"
          label="通知事项"
          placeholder="请选择通知事项"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getHistoryRecordsURL } from "./api";
import { setTypeOps, typeMap } from "./map.js";
import { createHTMLVNode, createImgVNode } from "@/utils/utils.js";
export default {
  name: "SMSHistory",
  data() {
    return {
      searchParam: {
        beginTime: "", //通知时间
        endTime: "",
        type: null, //通知事项
      },
      tableUrl: getHistoryRecordsURL,
      typeOps: setTypeOps(),
      headers: [
        {
          prop: "insertTime",
          label: "通知时间",
        },
        {
          prop: "type",
          label: "通知事项",
          formatter(row, prop) {
            return typeMap[row[prop]];
          },
        },
        {
          prop: "content",
          label: "事项内容",
        },
        {
          prop: "informNums",
          label: "通知人数",
        },
        {
          prop: "sucessNums",
          label: "已发送",
        },
        {
          prop: "faildNums",
          label: "未发送",
          formatter: (row, prop) => {
            let oBtn = `<span>${row.faildNums}</span> `;
            return createHTMLVNode(this, oBtn, row, prop, {
              cb: (e, row) => {
                this.$router.push({
                  name: "notConnectedList",
                  query: {
                    id: row.id,
                  },
                });
              },
            });
          },
        },
      ],
    };
  },

  computed: {
    createTime: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {},
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
