// 防疫信息列表
const getInfoListURL = `/gateway/hc-serve/manage/epidemicPrevention/getInfoList`;
// 发送短信
const sendFySmsURL = `/gateway/hc-serve/manage/epidemicPrevention/sendFySms`;
// 查询防疫短信历史记录
const getHistoryRecordsURL = `/gateway/hc-serve/manage/epidemicPrevention/getHistoryRecords`;
// 查询未发送的手机号
const getFaildMobilesURL = `/gateway/hc-serve/manage/epidemicPrevention/getFaildMobiles`;
// 获取网格长信息
const getGridInfoURL = `/gateway/hc-serve/manage/epidemicPrevention/getGridInfo`;

export {
  getInfoListURL,
  sendFySmsURL,
  getHistoryRecordsURL,
  getFaildMobilesURL,
  getGridInfoURL,
};
