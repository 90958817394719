import { mapHelper } from "@/utils/common.js";
// 最近一次核酸检测时间
const lastNucleicAcidTime = [
  {
    value: null,
    label: "全部",
  },
  // {
  //   value: 1,
  //   label: "0-24小时",
  // },
  // {
  //   value: 2,
  //   label: "24-48小时",
  // },
  // {
  //   value: 3,
  //   label: "48-72小时",
  // },
  // {
  //   value: 4,
  //   label: ">72小时",
  // },
  // {
  //   value: 5,
  //   label: ">7天",
  // },
  // {
  //   value: 6,
  //   label: "无核酸记录",
  // },
  {
    value: 1,
    label: "72小时内",
  },
  {
    value: 2,
    label: "72小时外",
  },
  {
    value: 3,
    label: "7天外",
  },
  {
    value: 4,
    label: "无核酸记录",
  },
];
const { map: lastNucleicAcidTimeMap, setOps: lastNucleicAcidTimeOps } =
  mapHelper.setMap(lastNucleicAcidTime);
// 健康码
const healthCode = [
  {
    value: null,
    label: "全部",
  },
  {
    value: 1,
    label: "绿码",
  },
  {
    value: 2,
    label: "黄码",
  },
  {
    value: 3,
    label: "红码",
  },
  {
    value: 4,
    label: "无记录",
  },
];
const { map: healthCodeMap, setOps: healthCodeOps } =
  mapHelper.setMap(healthCode);
// 是否接种疫苗
const vaccinationOrNot = [
  {
    value: null,
    label: "全部",
  },

  {
    value: 1,
    label: "已接种",
  },
  {
    value: 2,
    label: "未接种",
  },
  {
    value: 3,
    label: "无记录",
  },
  // {
  //   value: 1,
  //   label: "接种第一针",
  // },
  // {
  //   value: 2,
  //   label: "接种第二针",
  // },
  // {
  //   value: 3,
  //   label: "接种第三针",
  // },
  // {
  //   value: 4,
  //   label: "无接种历史",
  // },
];
const { map: vaccinationOrNotMap, setOps: vaccinationOrNotOps } =
  mapHelper.setMap(vaccinationOrNot);

// 通知事项
const type = [
  {
    value: null,
    label: "全部",
  },
  {
    value: 1,
    label: "核酸超过72小时",
  },
  {
    value: 2,
    label: "健康码为红码",
  },
  {
    value: 3,
    label: "健康码为黄码",
  },
];
const { map: typeMap, setOps: setTypeOps } = mapHelper.setMap(type);

export {
  lastNucleicAcidTimeMap,
  lastNucleicAcidTimeOps,
  healthCodeMap,
  healthCodeOps,
  vaccinationOrNotMap,
  vaccinationOrNotOps,
  setTypeOps,
  typeMap,
};
